import React, { useState, useEffect } from 'react'
import {
    Box,
    FormControl,
    InputLabel,
    Select,
    MenuItem
} from '@material-ui/core'
import LangItem from './LangItem'

const Controls = ({ allOperators, setFilteredOperators }) => {
    const languagesList = ['ua', 'ru', 'ar', 'en', 'isl']
    const [statusFilter, setStatusFilter] = useState('all')
    const [langFilter, setLangFilter] = useState('all')

    useEffect(() => {
        const hasLangFilter = langFilter !== 'all'
        const hasStatusFilter = statusFilter !== 'all'
        if (hasLangFilter || hasStatusFilter) {
            let preparedOperators = allOperators

            if (hasStatusFilter) {
                preparedOperators = preparedOperators.filter(
                    (item) => item.activity === statusFilter
                )
            }

            if (hasLangFilter) {
                preparedOperators = preparedOperators.filter((item) =>
                    item.languages.find((el) => el === langFilter)
                )
            }

            setFilteredOperators(preparedOperators)
        } else {
            setFilteredOperators(null)
        }
    }, [langFilter, statusFilter])

    const handleChangeStatus = (event) => {
        setStatusFilter(event.target.value)
    }
    const handleChangeLanguage = (event) => {
        setLangFilter(event.target.value)
    }

    return (
        <Box style={styles.wrapper}>
            <FormControl style={styles.item}>
                <Select
                    value={statusFilter}
                    onChange={handleChangeStatus}
                    inputProps={{ 'aria-label': 'Without label' }}
                >
                    <MenuItem value={'all'}>All</MenuItem>
                    <MenuItem value={'online'}>Free</MenuItem>
                    <MenuItem value={'on-break'}>Paused</MenuItem>
                </Select>
            </FormControl>
            <FormControl style={styles.item}>
                <Select
                    value={langFilter}
                    onChange={handleChangeLanguage}
                    inputProps={{ 'aria-label': 'Without label' }}
                >
                    <MenuItem value={'all'}>All</MenuItem>
                    {languagesList.map((lang) => (
                        <MenuItem value={lang} key={lang}>
                            {lang}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Box>
    )
}

export default Controls

const styles = {
    wrapper: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    item: {
        width: '45%'
    }
}
