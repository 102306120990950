import { Box, Typography } from '@material-ui/core';
import React from 'react';

const LangItem = ({ text }) => (
	<Box style={styles.background}>
		<Typography style={styles.text}>{text}</Typography>
	</Box>
);

export default LangItem;

const styles = {
	background: {
		padding: '4px 6px',
		backgroundColor: '#F2F2F2',
		borderRadius: 4,
		marginRight: 10,
	},
	text: {
		fontSize: 11,
	},
};
