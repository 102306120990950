import React, { useEffect, useState } from 'react'
import { Box, Grid } from '@material-ui/core'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Paper from '@material-ui/core/Paper'
import makeStyles from '@material-ui/core/styles/makeStyles'
import PropTypes from 'prop-types'

import { useCallContext } from '../../reducers/Call/CallProvider'
import ChatIcon from '../../assets/chatIcon.svg'
import ClientCardIcon from '../../assets/clientCardIcon.svg'
import ChatIconInactive from '../../assets/ChatInactive.svg'
import BadgeInactiveIcon from '../../assets/BadgeInactive.svg'
import { ClientCard } from './ClientCard'
import { Chat } from './Chat'
import { CallActionTypes } from '../../reducers/Call/CallActionsTypes'
import SwitchOperatorIcon from '../../assets/switch-operator.svg'
import SwitchOperator from '../SwitchOperator/SwitchOperator'

export const OperatorsPanel = () => {
    const {
        callState,
        sessionRef,
        dispatchCall,
        chatMessages,
        setChatMessages,
        rightTabId,
        setRightTabId
    } = useCallContext()
    const { selectedCall, selectedContact, selectedHistoryCall } = callState
    const [messages, setMessages] = useState([])
    const [isOpenSwitchModal, setIsOpenSwitchModal] = useState(false)

    const handleChange = (event, newValue) => {
        if (selectedHistoryCall.chat) {
            setRightTabId(newValue)
        }

        if (selectedCall.sessionId) {
            setIsOpenSwitchModal(newValue === 2)
        }

        if (newValue === 0) {
            if (selectedCall.sessionId) {
                setRightTabId(0)
                setMessages([])
            }
        }

        setRightTabId(newValue)
    }

    const closeTab = () => {
        setRightTabId(3)
        dispatchCall({
            type: CallActionTypes.SET_SELECTED_HISTORY,
            payload: {}
        })
    }
    useEffect(() => {
        if (selectedCall.sessionId) {
            setMessages([])
        } else closeTab()
    }, [selectedCall])

    useEffect(() => {
        if (!!selectedContact?.user?.login?.length) {
            setRightTabId(0)
        } else closeTab()
    }, [selectedContact])

    useEffect(() => {
        if (selectedHistoryCall.chat) {
            const chat = selectedHistoryCall.chat?.map((el) => {
                return {
                    from: el.from,
                    data: el.message,
                    sendedAt: el.sendedAt
                }
            })
            setMessages(chat)
        }
    }, [selectedHistoryCall])

    useEffect(() => {
        sessionRef?.current?.sessionHelper.session.on(
            'sessionDisconnected',
            async () => {
                setChatMessages([])
            }
        )
    }, [selectedCall.status === 'answered'])

    useEffect(() => {
        if (!sessionRef?.current) return
        closeTab()
    }, [sessionRef.current])

    return (
        <>
            <SwitchOperator
                isOpen={isOpenSwitchModal}
                setIsOpen={setIsOpenSwitchModal}
            />
            <Box
                style={{
                    margin: 0,
                    padding: 0,
                    backgroundColor: 'transparent'
                }}
            >
                <Box
                    flexDirection="row-reverse"
                    display="flex"
                    style={{ backgroundColor: 'transparent' }}
                >
                    <Box display="flex" style={{ width: 50 }}>
                        <Tabs
                            selectionFollowsFocus={true}
                            orientation="vertical"
                            value={rightTabId}
                            onChange={handleChange}
                            TabIndicatorProps={{
                                style: {
                                    backgroundColor: 'transparent'
                                }
                            }}
                        >
                            {(selectedContact?.user || sessionRef?.current) && (
                                <Tab
                                    icon={
                                        rightTabId === 0 ? (
                                            <img src={ClientCardIcon} />
                                        ) : (
                                            <img src={BadgeInactiveIcon} />
                                        )
                                    }
                                    style={{
                                        minWidth: 15,
                                        fontSize: 8
                                    }}
                                />
                            )}
                            {sessionRef?.current && (
                                <Tab
                                    icon={
                                        rightTabId === 1 ? (
                                            <img src={ChatIcon} />
                                        ) : (
                                            <img src={ChatIconInactive} />
                                        )
                                    }
                                    style={{
                                        minWidth: 15,
                                        fontSize: 8
                                    }}
                                />
                            )}
                            {sessionRef?.current && (
                                <Tab
                                    icon={<img src={SwitchOperatorIcon} />}
                                    style={{
                                        minWidth: 15,
                                        fontSize: 8,
                                        opacity: rightTabId === 2 ? 1 : 0.4
                                    }}
                                />
                            )}
                            <Tab
                                disabled={true}
                                style={{
                                    height: 0
                                }}
                            />
                        </Tabs>
                    </Box>
                    {/* ---------------------------------------------- */}
                    <Box
                        display="inline-block"
                        style={{ backgroundColor: 'transparent' }}
                    >
                        <TabPanel value={rightTabId} index={0}>
                            <ClientCard closeTab={closeTab} />
                        </TabPanel>
                        <TabPanel
                            value={rightTabId}
                            index={1}
                            style={{ backgroundColor: 'rgba(255,255,255,0)' }}
                        >
                            <Chat messages={chatMessages} closeTab={closeTab} />
                        </TabPanel>
                    </Box>
                </Box>
            </Box>
        </>
    )
}

const useStyles = makeStyles((theme) => ({
    mainFrameHeight: {
        height: 'calc(10vh - 70px)'
    },
    tabFrameHeight: {
        height: 'calc(10vh - 142px)',
        overflow: 'auto'
        // backgroundColor: 'pink',
    },
    listFrameHeight: {
        maxHeight: 'calc(10vh - 70px)'
    },
    interpreterPanel: {
        color: 'transparent',
        padding: '15px 10px'
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1
    },
    iconButton: {
        padding: 10
    }
}))

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired
}

function TabPanel(props) {
    const { children, value, index, ...other } = props

    return (
        <Paper
            square
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            style={{
                height: 'calc(10vh - 70ps -55px)'
            }}
            {...other}
        >
            <Grid container direction="column">
                {value === index && children}
            </Grid>
        </Paper>
    )
}
