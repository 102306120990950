import React, { useContext, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { selectTranslation, translationsGetAll } from '../../reducers/Translations/TranslationsSlice';
import VideoRecorder from 'react-video-recorder';
import { TranslationToolBar } from "./TranslationToolBar";
import { useActions } from "../../hooks/useActions";
import { FilePreview } from "./FilePreview";
import { DefaultPage } from "./DefaultPage";
import { PreviewPage } from "./PreviewPage";
import { RefusalForm } from "./RefusalForm";
import { makeStyles, Box, Button, Paper } from "@material-ui/core";
import { TranslationFiles } from "./TranslationFiles";
import { TranslationButtons } from "./TranslationButtons";
import { TranslationsService } from "../../services/TranslationsService";
import toast, { Toaster } from 'react-hot-toast';
import { useCallContext } from "../../reducers/Call/CallProvider";
import { applyTranslationFormat } from "../../utils/CustomDate";

// import { useSocket } from '../../hooks/useSocket';
// import { AuthContext } from '../../reducers/AuthReducer';
// import io from 'socket.io-client';
// import { AuthService } from "../../services/AuthService";

const useStyles = makeStyles({
	previewBox: {
		alignItems: 'center',
		justifyContent: 'space-between',
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		padding: '20px 0'
	},
	toast: {
		background: 'lime',
		display: 'flex',
		flexDirection: 'column',
		height: 'max-content',
		borderRadius: '4px',
		padding: '5px 25px',
		color: '#fff'
	}
});

export const TranslationPage = () => {
	const classes = useStyles();
	const {
		refuseToTranslate,
		signForTranslation,
		setIsPressed,
		clearRecords,
		addRecords,
		selectFile,
		setCurrentTranslations,
		setCompleted
	} = useActions();
	const {
		all,
		selectedId,
		selectedFileId,
		isPressed,
		signedForTranslation,
		refusedToTranslate,
		currentTranslations
	} = useSelector(state => state.translationsReducer);
	const { record, isCameraEnabled, recordToClear } = useSelector(state => state.recordsReducer);

	console.log(all);

	const notify = () => {
		toast('Wow so easy!');
	};

	const renderToast = ({ name, duration, startingAt }) => {
		const date = applyTranslationFormat(startingAt);

		return (
			<Paper className={classes.toast} elevation={5}>
				<Box>
					{name}
				</Box>
				<Box>
					{duration}
				</Box>
				<Box>
					{date}
				</Box>
			</Paper>
		);
	};

	useEffect(() => {
		setCurrentTranslations(all.find(item => item.id === selectedId));
		setIsPressed(false);
		signForTranslation(false);
		refuseToTranslate(false);
		setCompleted(false);
	}, [selectedId]);

	useEffect(() => {
		if (record && currentTranslations && selectedFileId !== null) {
			const translationsRecords = [...currentTranslations.records];
			const newTranslations = {
				...currentTranslations,
				records: translationsRecords.map((item, i) => {
					if (i === selectedFileId) {
						return record;
					}
					return item;
				})
			};
			setCurrentTranslations(newTranslations);
		}

		if (recordToClear !== null) {
			const translationsRecords = [...currentTranslations.records];

			setCurrentTranslations({
				...currentTranslations,
				records: translationsRecords.map((item, i) => {
					if (i === recordToClear) {
						return null;
					}
					return item;
				})
			});

			addRecords(null);
		}
	}, [record, recordToClear]);

	if (!selectedId) {
		return <DefaultPage />
	}

	if (!currentTranslations) {
		return null;
	}

	return isPressed ? (
		<>
			<TranslationToolBar />
			<FilePreview />
		</>
	) : (
		<Box className={classes.previewBox}>
			<Box position="absolute">
				<Toaster
					toastOptions={{
						className: classes.toast,
						duration: 5000
					}}
				/>
				{/* <Button onClick={notify}>
					Notify
				</Button> */}
			</Box>
			<TranslationFiles />
			{refusedToTranslate && <RefusalForm />}
			<TranslationButtons />
		</Box>
	);
}

