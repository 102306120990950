import React, { useState, useEffect, useRef } from 'react'
import { Modal, Typography, Box } from '@material-ui/core'
import Controls from './components/Controls'
import OperatorList from './components/OperatorList'
import Buttons from './components/Buttons'
import SuccessfullySwitched from './SuccessfullySwitched'
import { useTranslation } from 'react-i18next'
import { useCallContext } from '../../reducers/Call/CallProvider'
import { AuthService } from '../../services/AuthService'

const SwitchOperator = ({ isOpen, setIsOpen }) => {
    const { t } = useTranslation()
    const [allOperators, setAllOperators] = useState([])
    const [languagesList] = useState([])
    const [filteredOperators, setFilteredOperators] = useState(null)
    const [switchedOperator, setSwitchedOperator] = useState(null)
    const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false)
    const { switchOperator, callState } = useCallContext()

    const handleClose = () => {
        setIsOpen(false)
        setFilteredOperators(null)
        setSwitchedOperator(null)
    }

    const handleSwitch = () => {
        setIsOpen(false)
        setIsSuccessModalOpen(true)
        switchUser()
    }

    useEffect(() => {
        setAllOperators(
            callState.operators.filter(
                (el) =>
                    el?.activity !== 'offline' &&
                    el.login !== AuthService.getUser().email
            )
        )
    }, [callState.operators])

    const switchDisable = switchedOperator === null

    const switchUser = () => {
        switchOperator(switchedOperator.socketId)
    }

    return (
        <>
            <SuccessfullySwitched
                isOpen={isSuccessModalOpen}
                setIsOpen={setIsSuccessModalOpen}
                switchedOperator={switchedOperator}
                handleClose={handleClose}
            />
            <Modal open={isOpen} onClose={handleClose} style={styles.modal}>
                <Box style={styles.container}>
                    <Box style={styles.header}>
                        <Typography style={styles.title}>
                            {t('switchOperator')}
                        </Typography>
                        <Controls
                            allOperators={allOperators}
                            setFilteredOperators={setFilteredOperators}
                            languagesList={languagesList}
                        />
                    </Box>
                    <OperatorList
                        list={filteredOperators ?? allOperators}
                        operator={switchedOperator}
                        setOperator={setSwitchedOperator}
                    />
                    <Buttons
                        onCancel={handleClose}
                        onSwitch={handleSwitch}
                        switchDisable={switchDisable}
                    />
                </Box>
            </Modal>
        </>
    )
}

export default SwitchOperator

const styles = {
    modal: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        boxShadow:
            '0px 8px 10px rgba(0, 0, 0, 0.2), 0px 16px 24px rgba(0, 0, 0, 0.14), 0px 6px 30px rgba(0, 0, 0, 0.12)'
    },
    container: {
        backgroundColor: '#fff',
        width: '500px',
        borderRadius: 10,
        paddingTop: 20,
        paddingBottom: 20
    },
    header: {
        padding: '5px 20px 17px 11px'
    },
    title: {
        fontSize: 18,
        fontWeight: '600',
        marginBottom: 17
    }
}
