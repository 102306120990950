import React from "react";
import { useSelector } from "react-redux";
import { TranslationsService } from "../services/TranslationsService";
import { useActions } from "./useActions";

export const usePatchTranslationStatus = () => {
	const { translationsGetAll, setCurrentTranslations } = useActions();
	const {
		selectedId,
		currentTranslations,
		all
	} = useSelector(state => state.translationsReducer);

	return (status) => {
		console.log(status, 'status');

		TranslationsService.patchStatus({
			translationId: selectedId,
			status
		});
		translationsGetAll(all.map(item => {
			if (item.id === currentTranslations.id) {
				return {
					...item,
					status
				}
			}

			return item;
		}));
		const item = all.find(item => item.id === currentTranslations.id);

		setCurrentTranslations({
			...item,
			status,
			records: currentTranslations.records
		});

		console.log(item, 'check translations');
		console.log(currentTranslations, 'did current translations change?');
	}
};
