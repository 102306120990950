import React, { Fragment, useContext, useEffect, useState } from 'react'
import {
    HashRouter as Router,
    Switch,
    Route,
    Link as RouterLink
} from 'react-router-dom'
import PropTypes from 'prop-types'
import SignIn from './pages/signin/SignIn'
import { AuthContext } from './reducers/AuthReducer'
import { Box, Container, fade, Grid } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import makeStyles from '@material-ui/core/styles/makeStyles'
import ButtonBase from '@material-ui/core/ButtonBase'
import InputBase from '@material-ui/core/InputBase'
import SearchIcon from '@material-ui/icons/Search'
import {
    AccountCircle,
    AccountCircleOutlined,
    BusinessCenterOutlined,
    DialpadTwoTone,
    HistoryOutlined,
    InsertDriveFileOutlined,
    MoreHoriz,
    PhoneAndroid,
    PhoneOutlined,
    SettingsOutlined
} from '@material-ui/icons'
import Button from '@material-ui/core/Button'
import { AuthService } from './services/AuthService'
import Avatar from '@material-ui/core/Avatar'
import Badge from '@material-ui/core/Badge'
import Paper from '@material-ui/core/Paper'
import Popover from '@material-ui/core/Popover'
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state'
import withStyles from '@material-ui/core/styles/withStyles'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Divider from '@material-ui/core/Divider'
import { INTERPRETER_STATE } from './constants/defaultConstants'
import { BasePanel } from './components/BasePanel'
import { CallPage } from './pages/CallPage/CallPage'
import { CallProvider, useCallContext } from './reducers/Call/CallProvider'
import { OperatorsPanel } from './components/OperatorsPanel/OperatorsPanel'
import { ContactsPage } from './pages/ContactsPage/ContactsPage'
import { fullHeight } from './utils/constants'
import { TabTranslations } from './components/TabComponents/TabTranslations'
import { TranslationPage } from './pages/TranslationPage'
import { useSelector } from 'react-redux'
import { PageRouter } from './pages/PageRouter'
import BookingModal from './components/BookingModal/BookingModal'

export default function MainFrame() {
    const [authState] = useContext(AuthContext)
    const classes = useStyles()

    const { page } = useSelector((state) => state.paginationReducer)
    const { all } = useSelector((state) => state.translationsReducer)

    let authenticated
    if (authState.success !== undefined) {
        authenticated = authState.success
    } else {
        authenticated = authState.authenticated()
    }
    return (
        <Fragment>
            {authenticated ? (
                <>
                    <BookingModal />
                    <Box
                        style={{
                            justifyContent: 'flex-start',
                            flexDirection: 'row',
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            flex: 1
                        }}
                    >
                        <Box>
                            <BasePanel authState={authState} />
                        </Box>
                        <Box style={{ width: '100%' }}>
                            <PageRouter />
                        </Box>
                        <Box>
                            <OperatorsPanel authState={authState} />
                        </Box>
                    </Box>
                </>
            ) : (
                <SignIn />
            )}
        </Fragment>
    )
}

const useStyles = makeStyles((theme) => ({
    appHeight: {
        height: fullHeight
    }
}))
