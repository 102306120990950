import React from "react"
import { Box, ButtonBase, Divider, Typography } from "@material-ui/core"

import VideoCallIconHist from "../../assets/videoCallIconHist.svg"
import { useCallContext } from "../../reducers/Call/CallProvider"
import { CallService } from "../../services/CallService"
import { CallActionTypes } from "../../reducers/Call/CallActionsTypes"

export const RenderContact = () => {
	const { callState, dispatchCall, makeOutcomingCall } = useCallContext()
	const { contacts } = callState

	const { incomingCalls, historyCalls } = callState
	return contacts.map((el) => {
		return (
			<ButtonBase
				key={el.id}
				style={{
					width: "100%",
					paddingTop: 10
				}}
				onClick={async () => {
					// makeOutcomingCall(el.id);
					const res = await CallService.getContactData(el.id)
					dispatchCall({
						type: CallActionTypes.SET_SELECTED_CONTACT,
						payload: { user: res }
					})
				}}
			>
				<Box
					style={{
						paddingLeft: 10,
						paddingRight: 10,
						width: "100%"
					}}
				>
					<Typography variant="body1" align="left">
						{el?.profile?.last_name} {el?.profile?.first_name} {el?.profile?.patronymic}
					</Typography>
					<Typography variant="body1" align="left">
						{el?.employee?.department?.name}
					</Typography>
					<Typography variant="body1" align="left">
						{el.login}
					</Typography>

					<Divider
						style={{
							width: "100%"
						}}
					/>
				</Box>
			</ButtonBase>
		)
	})
}
