import React, {useState} from 'react'
import { Transition } from 'react-transition-group'

import {
    Box,
    Typography,
    Tabs,
    Tab,
    Paper,
    createStyles
} from '@material-ui/core'
import {
    AccountCircleOutlined,
    InsertDriveFileOutlined,
    PhoneOutlined
} from '@material-ui/icons'
import makeStyles from '@material-ui/core/styles/makeStyles'
import PropTypes from 'prop-types'
import { useCallContext } from '../reducers/Call/CallProvider'
import { Publisher } from './Publisher'
import { TabIncoming } from './TabIncoming'
import { TabContacts } from './TabComponents/TabContacts'
import { TabWrapper } from './TabWrapper'
import { fullHeight } from '../utils/constants'
import { TabTranslations } from './TabComponents/TabTranslations'
import { useDispatch, useSelector } from 'react-redux'
import { switchPage } from '../reducers/Pagination/PaginationSlice'
import { useTranslation } from 'react-i18next'
import AudioCallOff from "../assets/volume-off.svg";
import AudioCallOn from "../assets/volume-on.svg";

// animation staff
const duration = 1200
const defaultStyle = { transition: `all ${duration}ms ease-in-out` }
const transitionStyles = {
    entering: { paddingTop: 0 },
    entered: { paddingTop: 0 },
    exiting: { paddingTop: 0 },
    exited: { paddingTop: 0 }
}

const RenderItem = (item) => {
    const { tabIcon, dialItem, tabItem } = useStyles()
    const { t } = useTranslation()
    const Icon = !!item?.label?.length ? (
        <item.Icon className={tabIcon} />
    ) : (
        <Box className={dialItem}>
            <item.Icon className={tabIcon} />
        </Box>
    )
    return (
        <Tab
            key={item.id}
            disabled={item?.disabled}
            label={<Typography variant="button">{t(item?.label)}</Typography>}
            icon={Icon}
            className={tabItem}
        />
    )
}

const tabItems = [
    // { Icon: DialpadTwoTone, id: 0 },
    { label: 'calls', Icon: PhoneOutlined, id: 0 },
    { label: 'contacts', Icon: AccountCircleOutlined, id: 1 },
    {
        label: 'translations',
        Icon: InsertDriveFileOutlined,
        id: 2
    }
    // {
    // 	label: 'Інформація',
    // 	Icon: BusinessCenterOutlined,
    // 	disabled: true,
    // 	id: 4,
    // },
]

let timeout;

export const TabsPanel = () => {
    // const [value, setValue] = useState(3);
    // const [authState, dispatch] = useContext(AuthContext);
    const dispatch = useDispatch()
    const { page } = useSelector((state) => state.paginationReducer)
    const { isCameraEnabled } = useSelector((state) => state.recordsReducer)
    const { callState, isVolumeOn, setIsVolumeOn } = useCallContext()
    const styles = useStyles()

    const muteSoundHandler = () => {
        if (timeout) clearTimeout(timeout)
        if (isVolumeOn) {
            setIsVolumeOn(false)
            timeout = setTimeout(() => {
                setIsVolumeOn(true)
            }, 180000)
        } else {
            setIsVolumeOn(true)
        }
    }

    const handleChange = (event, newValue) => {
        dispatch(switchPage(newValue))
        console.log(newValue)
    }

    const { selectedCall, incomingCalls } = callState
    const isVideoActive =
        !!incomingCalls.length || !!selectedCall.sessionId.length
    return (
        <Box
            style={{
                width: 420,
                margin: 0,
                paddingTop: 0,
                height: fullHeight,
                overflowY: 'clip'
            }}
        >
            <Box flexDirection="row" display="flex" height="100%">
                <Box
                    display="flex"
                    width={64}
                    bgcolor="#008DFF"
                    flexDirection="column"
                    color="white"
                    height="100%"
                >
                    <Publisher />
                    <div style={{display: 'flex', flexDirection: 'column',  width: 64, justifyContent: 'space-between', height: '100%', alignItems: 'center'}}>
                        <Tabs
                            selectionFollowsFocus={true}
                            orientation="vertical"
                            variant="scrollable"
                            value={page}
                            onChange={handleChange}
                            textColor="primary"
                            TabIndicatorProps={{
                                style: {
                                    width: 64,
                                    height: 70,
                                    zIndex: 1,
                                    backgroundColor: 'white',
                                    borderTopLeftRadius: 4,
                                    borderBottomLeftRadius: 4
                                }
                            }}
                        >
                            {tabItems.map((el) => RenderItem(el))}
                        </Tabs>
                        <Box
                            style={{
                                width: 42,
                                height: 42,
                                borderRadius: "50%",
                                backgroundColor: "#9C9C9C",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                cursor: "pointer",
                                marginBottom: 20
                            }}
                        >
                            <img
                                onClick={muteSoundHandler}
                                src={isVolumeOn ? AudioCallOff : AudioCallOn}
                                style={{
                                    width: 25,
                                    height: 25
                                }}
                            />
                        </Box>
                    </div>

                </Box>
                {/* ---------------------------------------------- */}
                <Transition in={!isVideoActive} timeout={duration}>
                    {(state) => (
                        <Box
                            style={{
                                ...defaultStyle,
                                ...transitionStyles[state],
                                marginTop: 265
                            }}
                            display="inline-block"
                        >
                            {/* <TabPanel value={page} index={0}>
								<PhoneInput />
							</TabPanel> */}

                            <TabPanel value={page} index={0}>
                                <TabIncoming />
                            </TabPanel>

                            <TabPanel value={page} index={1}>
                                <TabContacts />
                            </TabPanel>

                            <TabPanel value={page} index={2}>
                                <TabTranslations />
                            </TabPanel>
                        </Box>
                    )}
                </Transition>
            </Box>
        </Box>
    )
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired
}

function TabPanel(props) {
    const { children, value, index, ...other } = props

    return (
        <Paper
            square
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            style={{
                height: fullHeight
            }}
            {...other}
        >
            <TabWrapper value={value}>{value === index && children}</TabWrapper>
        </Paper>
    )
}

const useStyles = makeStyles((theme) =>
    createStyles({
        tabItem: {
            minWidth: 15,
            height: 64,
            zIndex: 2,
            color: '#fff'
        },
        dialItem: {
            width: 40,
            height: 40,
            borderRadius: 40,
            backgroundColor: '#fff',
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
            color: '#008DFF'
        },
        tabIcon: {
            width: 24,
            height: 24,
            margin: '0px 20px 0px 20px'
        }
    })
)
