import React from "react";
import { useSelector } from "react-redux";
import { Box, makeStyles } from "@material-ui/core";
// import { fullHeight } from "../../utils/constants";

const useStyles = makeStyles({
	filesBox: {
		width: '100%',
		height: '100%',
		maxHeight: '92vh',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		overflow: 'hidden',
	}
});

export const FilePreview = () => {
	const { rotation, zoom } = useSelector(state => state.fileReducer);
	const { selectedFileId, currentTranslations } = useSelector(state => state.translationsReducer);
	const classes = useStyles();
	const image = {
		transform: `rotate(${rotation}turn) scale(${zoom})`,
		transition: 'transform 0.5s',
		marginTop: '20px',
		maxHeight: '800px',
		maxWidth: '600px',
		minHeight: '600px'
	};
	const url = `https://dev.api.soc.business/v2/filesystem${currentTranslations.files[selectedFileId].file}`;

	return (
		<Box className={classes.filesBox}>
			<img
				src={url}
				alt="translation file"
				style={image}
			/>
		</Box>
	);
}
