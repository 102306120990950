import { makeStyles, Box, Button } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useActions } from "../../hooks/useActions";
import { usePatchTranslationStatus } from "../../hooks/usePatchTranslationStatus";
import { TranslationsService } from "../../services/TranslationsService";

const useStyles = makeStyles({
	button: {
		width: 'max-content',
		height: '36px',
		fontSize: '14px',
		textTransform: 'uppercase'
	},
	buttonBox: {
		display: 'flex',
		gap: '26px',
		justifyContent: 'flex-end',
		width: '100%'
	},
});

export const TranslationButtons = () => {
	const classes = useStyles();
	const {
		signForTranslation,
		refuseToTranslate,
		setCompleted,
		setCurrentTranslations
	} = useActions();
	const {
		signedForTranslation,
		selectedId,
		currentTranslations,
		completed,
		all
	} = useSelector(state => state.translationsReducer);
	const patchStatus = usePatchTranslationStatus();
	
	const accept = () => {
		signForTranslation(true);
		patchStatus('processing');
	};
	const sendData = () => {
		const data = new FormData();
		const names = (size) => {
			const arr = [];

			for (let i = 0; i < size; i++) {
				arr.push(`name ${i}.blob`);
			}

			return arr;
		};
		const uniqueNames = names(3);

		data.append('translationRequestId', selectedId);
		currentTranslations.records?.forEach((video, i) => {
			if (video === null || typeof video === 'string') {
				data.append('video', new File([], 'empty'));
			} else {
				data.append('video', video, uniqueNames[i]);
			}
			// console.log('video', video);
		});
		TranslationsService.patchTranslation(data);
		patchStatus('completed');
		setCompleted(true);
	}

	return (
		<Box className={classes.buttonBox}>
			{(!signedForTranslation && currentTranslations.status === 'created') ? (
				<>
					<Button
						variant="outlined"
						color="primary"
						className={classes.button}
						onClick={() => refuseToTranslate(true)}
					>
						Відмовити
					</Button>
					<Button
						variant="contained"
						color="primary"
						className={classes.button}
						onClick={accept}
						disableElevation
					>
						Взяти в переклад
					</Button>
				</>
			) : (currentTranslations.status !== 'completed' && !completed) && (
			// ) : (
				<Button
					variant="contained"
					color="primary"
					className={classes.button}
					style={{ width: '170px' }}
					onClick={sendData}
				>
					Закрити заявку
				</Button>
			)}
		</Box>
	);
};
