import React, { useEffect, useState } from 'react';
import { Box, ButtonBase } from '@material-ui/core';
import { RenderContact } from './RenderContact';
import { SearchInput } from '../SearchInput';
import { CallService } from '../../services/CallService';
import { useCallContext } from '../../reducers/Call/CallProvider';
import { CallActionTypes } from '../../reducers/Call/CallActionsTypes';
import { Console } from '../../debug/Console';
import { TabWrapper } from '../TabWrapper';

export const TabContacts = () => {
	const { callState, dispatchCall } = useCallContext();
	const [page, setPage] = useState(1);
	const [searchable, setSearchable] = useState('');

	const getAllContacts = async (firstPage = false) => {
		const contacts = await CallService.getContacts(
			firstPage ? 1 : callState.nextPage
		);
		dispatchCall({
			type: CallActionTypes.GET_CONTACTS,
			payload: contacts,
		});
	};

	useEffect(() => {
		getAllContacts(true);
		return () => {
			dispatchCall({ type: CallActionTypes.CLEAR_CONTACTS });
			dispatchCall({
				type: CallActionTypes.SET_SELECTED_CONTACT,
				payload: {},
			});
		};
	}, []);

	const getFindContact = async (value, firstPage = false) => {
		const res = await CallService.findContacts(
			value || searchable,
			firstPage ? 1 : callState.nextPage
		);
		dispatchCall({
			type: CallActionTypes.GET_CONTACTS,
			payload: res,
		});
	};

	const handleClick = async (value) => {
		if (value.length) {
			try {
				value = value.replace(/[-!$%^&*()_+|~=`{}\[\]:";'<>?,.\/]/, '');
				dispatchCall({ type: CallActionTypes.CLEAR_CONTACTS });
				getFindContact(value, true);
				setSearchable(value);
			} catch (err) {
				dispatchCall({
					type: CallActionTypes.GET_CONTACTS,
					payload: { data: [] },
				});
			}
		} else {
			setSearchable('');
			await dispatchCall({ type: CallActionTypes.CLEAR_CONTACTS });
			await getAllContacts(true);
		}
	};
	return (
		<>
			{/* <SearchInput handleClick={handleClick} /> */}

			<RenderContact />

			{callState.nextPage && (
				<ButtonBase
					onClick={() =>
						!!searchable.length
							? getFindContact()
							: getAllContacts()
					}
					style={{
						width: '100%',
						bottom: 0,
						height: 50,
						backgroundColor: '#00000030',
					}}
				>
					Завантажити ще
				</ButtonBase>
			)}
		</>
	);
};
