import React, { useState, useEffect } from 'react'
import {
    Box,
    Typography,
    FormGroup,
    FormControlLabel,
    Checkbox
} from '@material-ui/core'
import LangItem from './LangItem'
import { useTranslation } from 'react-i18next'

const OperatorItem = ({ item, operator, setOperator }) => {
    const { t } = useTranslation()
    const [isOperatorSelected, setIsOperatorSelected] = useState(false)

    const hasSelectedOperator = operator?.id === item.id

    useEffect(() => {
        setIsOperatorSelected(hasSelectedOperator)
    }, [operator])

    const handleSelect = () => {
        if (hasSelectedOperator) {
            setOperator(null)
        } else {
            setOperator(item)
        }
    }

    return (
        <Box
            style={{
                ...styles.container,
                ...{
                    backgroundColor: hasSelectedOperator ? '#F0F1F9' : '#FFF'
                }
            }}
        >
            {/* <img src={item.icon} alt="avatar" style={styles.avatar} /> */}
            <Box>
                <Box
                    style={{
                        ...styles.status,
                        ...styles.statuses[item.activity]
                    }}
                >
                    <Typography style={styles.statusText}>
                        {t(item.activity)}
                    </Typography>
                </Box>
                <Typography style={styles.title}>{item.login}</Typography>
                {item?.languages && (
                    <Box style={styles.languages}>
                        {item.languages.map((lang) => (
                            <LangItem text={lang} key={lang} />
                        ))}
                    </Box>
                )}
            </Box>
            <Box style={styles.checkboxWrapper}>
                <FormControlLabel
                    control={
                        <Checkbox
                            onChange={handleSelect}
                            color="primary"
                            checked={isOperatorSelected}
                        />
                    }
                    label={t('select')}
                />
            </Box>
        </Box>
    )
}

export default OperatorItem

const styles = {
    container: {
        display: 'flex',
        alignItems: 'center',
        padding: '10px',
        borderTop: '1px solid #C4C4C4',
        borderBottom: '1px solid #C4C4C4'
    },
    avatar: {
        width: 30,
        height: 30,
        marginRight: 10
    },
    status: {
        width: 50,
        height: 16,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 99
    },
    statuses: {
        online: {
            backgroundColor: '#1CB522'
        },
        'on-break': {
            backgroundColor: '#EFBE29'
        }
    },
    statusText: {
        color: '#ffffff',
        fontSize: 10
    },
    title: {
        fontSize: 16,
        margin: '7px 0'
    },
    languages: {
        display: 'flex'
    },
    checkboxWrapper: {
        marginLeft: 'auto'
    }
}
