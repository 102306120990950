import React, { useContext, useEffect, useState } from 'react';
import Divider from '@material-ui/core/Divider';
import { Box, Container, Grid } from '@material-ui/core';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Button from '@material-ui/core/Button';
import {
	HistoryOutlined,
	InsertDriveFileOutlined,
	PhoneAndroid,
	PhoneOutlined,
	RingVolume,
	SettingsOutlined,
} from '@material-ui/icons';
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import ButtonBase from '@material-ui/core/ButtonBase';
import Paper from '@material-ui/core/Paper';
import { SearchInput } from './SearchInput';
import { AuthContext } from '../reducers/AuthReducer';
import { useCallContext } from '../reducers/Call/CallProvider';
import { RenderCalls } from '../pages/CallPage/RenderCalls';
import { CallService } from '../services/CallService';
import PropTypes from 'prop-types';
import { CallHistory } from '../pages/CallPage/CallHistory';
import { fullHeight } from '../utils/constants';
import { useTranslation } from 'react-i18next';

export const TabIncoming = () => {
	const [isHistoryRender, setisHistoryRender] = useState(0);

	return (
		<Box
			style={{
				padding: 0,
				margin: 0,
			}}
		>
			<TabPanel value={isHistoryRender} index={0}>
				<RenderCalls isHistoryRender={false} />
			</TabPanel>
			<TabPanel value={isHistoryRender} index={1}>
				<CallHistory />
			</TabPanel>
			<Box style={{ position: 'absolute', bottom: 0, width: 356 }}>
				<IconLabelTabs
					value={isHistoryRender}
					setValue={setisHistoryRender}
				/>
			</Box>
		</Box>
	);
};

function IconLabelTabs({ value, setValue }) {
	const { t } = useTranslation();
	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	return (
		<Paper
			square
			style={{
				boxShadow: '0px -4px 5px rgba(0, 0, 0, 0.14)',
			}}
		>
			<Tabs
				style={{
					height: 80,
					width: '100%',
				}}
				value={value}
				onChange={handleChange}
				variant="fullWidth"
				indicatorColor="primary"
				textColor="primary"
				aria-label="icon label tabs example"
			>
				<Tab
					icon={<RingVolume />}
					label={t('activeCalls')}
					style={{
						minWidth: 0,
						fontSize: 12,
					}}
				/>
				<Tab
					icon={<HistoryOutlined />}
					label={t('history')}
					style={{
						minWidth: 0,
						fontSize: 12,
					}}
				/>
			</Tabs>
		</Paper>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<Paper
			square
			role="tabpanel"
			hidden={value !== index}
			id={`vertical-tabpanel-${index}`}
			aria-labelledby={`vertical-tab-${index}`}
			style={
				{
					// height: `calc(100vh - 120px)`,
					// overflowY: 'auto',
				}
			}
			{...other}
		>
			{value === index && children}
		</Paper>
	);
}
