import React, { useState } from "react"
import {
	Box,
	Modal,
	Button,
	Typography,
	makeStyles,
	IconButton,
	Paper,
	TextField
} from "@material-ui/core"
import { useSelector } from "react-redux"
import cross from "../../assets/modal_window_cross.svg"
import { useActions } from "../../hooks/useActions"
import { applyTranslationFormat } from "../../utils/CustomDate"
import { TranslationsService } from "../../services/TranslationsService"
import { useTranslation } from "react-i18next"

// const style = {
//   position: 'absolute',
//   top: '1000px',
//   left: '50%',
//   transform: 'translate(-50%, -50%)',
//   width: 400,
//   background: 'red',
//   border: '2px solid #000',
//   boxShadow: 24,
//   p: 4,
// };

const useStyles = makeStyles({
	modalWindow: {
		position: "absolute",
		top: "25%",
		left: "25%",
		background: "#fff",
		width: "850px",
		height: "500px",
		display: "flex",
		flexDirection: "column",
		borderRadius: "16px"
	},
	header: {
		background: "#008DFF",
		height: "85px",
		display: "flex",
		alignItems: "center",
		borderRadius: "16px 16px 0 0"
	},
	title: {
		width: "350px",
		padding: "20px",
		color: "#fff",
		flexGrow: 1
	},
	request: {
		display: "flex",
		gap: "16px"
	},
	info: {
		display: "flex",
		flexDirection: "column",
		gap: "12px",
		marginTop: "12px",
		marginRight: "25px"
	},
	numbers: {
		display: "flex",
		gap: "16px"
	},
	content: {
		width: "calc(100% - 50px)",
		height: "100%",
		margin: "25px",
		display: "flex",
		flexDirection: "column",
		justifyContent: "space-between"
	},
	buttons: {
		display: "flex",
		width: "100%",
		justifyContent: "flex-end",
		gap: "16px"
	},
	button: {
		width: "140px",
		textTransform: "uppercase"
	}
})

export const RefusalForm = () => {
	const { t } = useTranslation()
	const classes = useStyles()
	const { refusedToTranslate, currentTranslations } = useSelector(
		(state) => state.translationsReducer
	)
	const { refuseToTranslate } = useActions()
	const { name, user, created_at, id } = currentTranslations
	const [query, setQuery] = useState("")

	const date = applyTranslationFormat(created_at)

	const sendRefusal = () => {
		TranslationsService.postMessage({
			translationId: id,
			message: query
		})
	}

	return (
		<div>
			<Modal open={refusedToTranslate}>
				<Paper className={classes.modalWindow} elevation={8}>
					<Box className={classes.header}>
						<Box className={classes.title}>
							<Typography
								style={{
									color: "#fff",
									textTransform: "uppercase",
									fontWeight: "bold",
									fontSize: "24px",
									lineHeight: "28px",
									marginBottom: "6px"
								}}
							>
								{t("translationsTab.denyReason")}
							</Typography>
							<Box className={classes.request}>
								<Typography
									style={{
										color: "#fff",
										fontSize: "14px",
										lineHeight: "13px"
									}}
								>
									{t("translationsTab.orderNumber")}
								</Typography>
								<Typography
									style={{
										color: "#fff",
										fontSize: "14px",
										lineHeight: "13px",
										fontWeight: "bold"
									}}
								>
									{id}
								</Typography>
							</Box>
						</Box>

						<Box className={classes.info}>
							<Typography
								style={{
									color: "#fff",
									fontSize: "16px",
									lineHeight: "13px"
								}}
							>
								{user?.member
									? `${user.member.lastName} ${user.member.firstName} ${user.member.patronymic}`
									: t("translationsTab.notProvided")}
							</Typography>
							<Box className={classes.numbers}>
								<Typography
									style={{
										color: "#fff",
										fontSize: "14px",
										lineHeight: "13px",
										opacity: "0.6"
									}}
								>
									{user.login}
								</Typography>
								<Typography
									style={{
										color: "#fff",
										fontSize: "14px",
										lineHeight: "13px",
										opacity: "0.6"
									}}
								>
									{date}
								</Typography>
							</Box>
						</Box>
						<IconButton style={{ bottom: 15, right: 10 }} onClick={() => refuseToTranslate(false)}>
							<img src={cross} />
						</IconButton>
					</Box>
					<Box className={classes.content}>
						<TextField
							label={t("translationsTab.comment")}
							placeholder={t("translationsTab.denyReasonPlaceholder")}
							variant="outlined"
							multiline
							style={{ width: " 100%" }}
							rows={14}
							value={query}
							onChange={(e) => setQuery(e.target.value)}
						/>
						<Box className={classes.buttons}>
							<Button
								className={classes.button}
								variant="outlined"
								color="primary"
								size="large"
								onClick={() => refuseToTranslate(false)}
							>
								{t("translationsTab.cancel")}
							</Button>
							<Button
								className={classes.button}
								variant="contained"
								disableElevation
								color="primary"
								size="large"
								onClick={sendRefusal}
							>
								{t("translationsTab.cancel")}
							</Button>
						</Box>
					</Box>
				</Paper>
			</Modal>
		</div>
	)
}
