import { Box } from '@material-ui/core'
import React, { useEffect, useRef, useState } from 'react'
import { Transition } from 'react-transition-group'
import { useCallContext } from '../reducers/Call/CallProvider'
import { ButtonBase } from '@material-ui/core'
import CamOnIcon from '../assets/camOnIcon.svg'
import CamOffIcon from '../assets/camOffIcon.svg'
import MicOnIcon from '../assets/micOnIcon.svg'
import MicOffIcon from '../assets/micOffIcon.svg'
import OT from '@opentok/client'
import { AuthService } from '../services/AuthService'

export const Publisher = () => {
    const [publishAudio, setPublishAudio] = useState(true)
    const [isShowSettings, setIsShowSettings] = useState(false)
    const publisherRef = useRef(null)
    const {
        setPublisher,
        publisher,
        callState,
        sessionRef,
        publisherVideo,
        setPublisherVideo,
        publisherAudio,
        setPublisherAudio
    } = useCallContext()

    const resizeVideo = () => {
        console.log(this.publisher)
        let originalWidth = publisher.videoWidth()
        let originalHeight = publisher.videoHeight()
        let width = publisher.element.parentElement.clientWidth
        let height = (width * originalHeight) / originalWidth
        publisher.element.style.width = width + 'px'
        publisher.element.style.height = height + 'px'
    }

    const isVideoActive = true

    const initPublisher = () => {
        let videoSource = null
        let device = null
        let newPublisher = null
        publisherRef.current.innerHTML = null

        OT.getDevices((error, devices) => {
            videoSource = devices.find(
                (el) => el.label === 'OBS Virtual Camera'
            )
            device = devices.find((el) => el.kind === 'videoInput')
        })

        newPublisher = OT.initPublisher('publisher-element', {
            insertMode: 'append',
            resolution: '640x480',
            publishVideo: true,
            publishAudio: true,
            name: AuthService.getUser().email + ' (operator)',
            width: '100%',
            height: 265,
            fitMode: 'contain',
            showControls: false,
            videoSource: videoSource?.deviceId ?? device?.deviceId
        })

        newPublisher.on('videoDimensionsChanged', () => {
            resizeVideo()
        })

        setPublisherVideo(true)
        setPublisherAudio(true)
        setPublisher(newPublisher)
    }

    useEffect(() => {
        initPublisher()
    }, [])

    useEffect(() => {
        publisher?.on('destroyed', () => initPublisher())
    }, [publisher])

    const duration = 1200

    const defaultStyle = {
        transition: `all ${duration}ms ease-in-out`
    }

    const transitionStyles = {
        entering: {
            //
            transformOrigin: 'left',
            transform: 'scaleX(1)'
        },
        entered: {
            transform: 'scaleX(1)',
            transformOrigin: 'left'
        },
        exiting: {
            transform: 'scaleX(0.15)',
            transformOrigin: 'left'
        },
        exited: {
            transform: 'scaleX(0.15)',
            transformOrigin: 'left'
        }
    }

    const boxTransition = {
        entering: {
            opacity: 0
        },
        entered: {
            opacity: 0
        },
        exiting: {
            opacity: 1
        },
        exited: {
            opacity: 1
        }
    }

    const iconTransition = {
        entering: {
            transform: 'scale(1, 1)'
        },
        entered: {
            transform: 'scale(1, 1)'
        },
        exiting: {
            transform: 'scale(3.3, 0.5)'
        },
        exited: {
            transform: 'scale(3.3, 0.5)'
        }
    }

    return (
        <Transition in={isVideoActive} timeout={duration}>
            {(state) => (
                <Box
                    style={{
                        position: 'relative',
                        zIndex: 100,
                        marginLeft: 64,
                        width: 355,
                        height: 265,
                        ...defaultStyle,
                        ...transitionStyles[state]
                    }}
                >
                    <div
                        id="publisher-element"
                        ref={publisherRef}
                        style={{ width: '100%' }}
                    />
                    <div
                        style={{
                            position: 'absolute',
                            bottom: 20,
                            left: '50%',
                            zIndex: 1000,
                            display: 'flex',
                            width: '25%',
                            justifyContent: 'space-between',
                            transform: 'translateX(-50%)'
                        }}
                    >
                        <ButtonBase
                            onClick={() => {
                                setPublisherVideo((prev) => !prev)
                            }}
                            style={{
                                borderRadius: '50%'
                            }}
                        >
                            <img
                                style={{ width: 40, height: 40 }}
                                src={publisherVideo ? CamOnIcon : CamOffIcon}
                            />
                        </ButtonBase>
                        <ButtonBase
                            onClick={() => {
                                setPublisherAudio((prev) => !prev)
                            }}
                            style={{
                                borderRadius: '50%'
                            }}
                        >
                            <img
                                style={{ width: 40, height: 40 }}
                                src={publisherAudio ? MicOnIcon : MicOffIcon}
                            />
                        </ButtonBase>
                    </div>
                </Box>
            )}
        </Transition>
    )
}
