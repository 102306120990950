import React from 'react'
import { Box, ButtonBase, Typography } from '@material-ui/core'
import VideoCallIcon from '../../assets/videoCallIcon.svg'
import FireIcon from '../../assets/fireIcon.svg'
import EmergencyIcon from '../../assets/ambulanceIcon.svg'
import GasIcon from '../../assets/gasIcon.svg'
import PoliceIcon from '../../assets/policeIcon.svg'
import CallRequestIcon from '../../assets/callOutIcon.svg'

import CallLottie from '../../assets/lottie/call-normal.json'
import RedLottie from '../../assets/lottie/call-101-103.json'
import BlueLottie from '../../assets/lottie/call-102.json'
import YellowLottie from '../../assets/lottie/call-104.json'

import RuIcon from '../../assets/ru-icon.svg'
import UaIcon from '../../assets/ua-icon.svg'
import EnIcon from '../../assets/en-icon.svg'
import ArIcon from '../../assets/ar-icon.svg'
import PkIcon from '../../assets/pk-icon.svg'
import InIcon from '../../assets/in-icon.svg'
import IslIcon from '../../assets/isl-icon.svg'
import CallIcon from '../../assets/call-icon.svg'

import PoliceIconSmall from '../../assets/police-icon.svg'
import HeartIconSmall from '../../assets/heart-icon.svg'
import GasIconSmall from '../../assets/gas-icon.svg'
import LawIconSmall from '../../assets/lawyer-icon.svg'
import DoctorIconSmall from '../../assets/doctor-icon.svg'
import FireIconSmall from '../../assets/fire-icon.svg'

import { CallActionTypes } from '../../reducers/Call/CallActionsTypes'
import { useCallContext } from '../../reducers/Call/CallProvider'
import { Timer } from '../../utils/Timer'
import { useTranslation } from 'react-i18next'
import { reasonTypes } from './types'

type languageType = 'ru' | 'en' | 'ua' | 'pk' | 'ar' | 'in' | 'isl'

export const RenderCalls = () => {
    // @ts-ignore
    const { callState, dispatchCall, handleAccept } = useCallContext()
    const { t } = useTranslation()

    const { incomingCalls, selectedCall, othersCalls } = callState

    const handleSetCall = (el: any) => {
        dispatchCall({
            type: CallActionTypes.PREVIEW_CALL,
            payload: el
        })

        console.log('HERE', el)

        handleAccept()
    }

    const isRenderSelected =
        !!selectedCall.sessionId.length && selectedCall.status === 'answered'

    const calls = isRenderSelected
        ? [selectedCall, ...incomingCalls, ...othersCalls]
        : [...incomingCalls, ...othersCalls]
    return calls.length > 0 ? (
        calls.map((el) => {
            const data = getMetaData(el.reason)

            let userLang = el.lang

            if (el.user.app === 'amsaan') {
                userLang = el.user?.languages[0]
            }

            let userCountryFlag = null

            if (userLang) {
                userCountryFlag = getCountry(userLang)
            }

            const { icon, text } = getCallReason(el.reason)

            const isGroupCall = el.reason === 'group'

            return (
                <ButtonBase
                    key={el.sessionId}
                    style={{
                        width: '100%',
                        paddingTop: 10,
                        borderBottom: '1px solid #ccc',
                        backgroundColor:
                            el.sessionId === selectedCall.sessionId &&
                            el.status === 'answered'
                                ? '#008DFF30'
                                : el?.status === 'reserved'
                                ? '#00000030'
                                : isGroupCall
                                ? '#E7AC39'
                                : 'transparent'
                    }}
                    disabled={isRenderSelected || el?.status === 'reserved'}
                    onClick={handleSetCall.bind(null, el)}
                >
                    <Box
                        style={{
                            width: 100,
                            marginRight: 20,
                            marginLeft: 10,
                            height: 'auto',
                            display: 'flex',
                            flexDirection: 'column',
                            position: 'relative',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    >
                        {data && (
                            <>
                                {data?.lottie && data?.icon && data?.text && (
                                    <>
                                        <img
                                            src={CallIcon}
                                            alt={data?.text}
                                            style={{ marginBottom: 10 }}
                                        />
                                        {icon && text && (
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    width: 70,
                                                    height: 20,
                                                    border: '1px solid #008DFF',
                                                    borderRadius: 57,
                                                    marginBottom: 10
                                                }}
                                            >
                                                <img
                                                    src={icon}
                                                    alt={text}
                                                    style={{ marginRight: 5 }}
                                                />
                                                <p
                                                    style={{
                                                        fontSize: 10,
                                                        color: '#008DFF'
                                                    }}
                                                >
                                                    {t(text)}
                                                </p>
                                            </div>
                                        )}
                                        {userCountryFlag && (
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    marginBottom: 15
                                                }}
                                            >
                                                <img
                                                    src={userCountryFlag}
                                                    alt={userLang}
                                                    style={{ marginRight: 5 }}
                                                />
                                                {userLang.toUpperCase()}
                                            </div>
                                        )}
                                    </>
                                )}
                            </>
                        )}
                    </Box>

                    <Box
                        style={{
                            padding: 0,
                            width: '100%'
                        }}
                    >
                        <Typography variant="body1">
                            {el.user?.employee?.department?.name}
                        </Typography>
                        <Typography variant="body1">
                            {el.user?.profile?.last_name}{' '}
                            {el.user?.profile?.first_name}{' '}
                            {el.user?.profile?.patronymic}
                        </Typography>
                        <Typography variant="body1">
                            {el?.user?.login ?? el.user?.properties?.name}
                        </Typography>
                        {data?.text && (
                            <Typography variant="subtitle1">
                                {t(data?.text)}
                            </Typography>
                        )}
                        <Typography variant="body1">
                            <Timer start={new Date(el.startedAt)} />
                        </Typography>
                    </Box>
                </ButtonBase>
            )
        })
    ) : (
        <Box
            style={{
                display: 'flex',
                justifyItems: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                justifyContent: 'center',
                height: '100%'
            }}
        >
            <Typography variant="subtitle1">{t('callsHere')}</Typography>
        </Box>
    )
}

const getMetaData = (reason: reasonTypes) => {
    switch (reason) {
        case 'emergency':
            return {
                lottie: RedLottie,
                icon: EmergencyIcon,
                text: 'callTypes.103'
            }
        case 'fire department':
            return {
                lottie: RedLottie,
                icon: FireIcon,
                text: 'callTypes.101'
            }
        case 'police':
            return {
                lottie: BlueLottie,
                icon: PoliceIcon,
                text: 'callTypes.102'
            }
        case 'gas service':
            return {
                lottie: YellowLottie,
                icon: GasIcon,
                text: 'callTypes.104'
            }
        case 'other':
            return {
                lottie: CallLottie,
                icon: VideoCallIcon,
                text: 'callTypes.videoCall'
            }
        case 'group':
            return {
                lottie: CallLottie,
                icon: VideoCallIcon,
                text: 'callTypes.groupCall'
            }
        case 'request':
            return {
                lottie: CallLottie,
                icon: CallRequestIcon,
                text: 'callTypes.callRequest'
            }
        default:
            return {
                lottie: CallLottie,
                icon: VideoCallIcon,
                text: 'callTypes.videoCall'
            }
    }
}

const getCountry = (country: languageType) => {
    switch (country) {
        case 'ua':
            return UaIcon
        case 'ru':
            return RuIcon
        case 'en':
            return EnIcon
        case 'ar':
            return ArIcon
        case 'in':
            return InIcon
        case 'pk':
            return PkIcon
        case 'isl':
            return IslIcon
        default: 
            return null
    }
}

const getCallReason = (reason: string) => {
    switch (reason) {
        case 'emergency':
            return {
                icon: DoctorIconSmall,
                text: 'doctorCall'
            }
        case 'fire':
            return {
                icon: FireIconSmall,
                text: 'fireCall'
            }
        case 'police':
            return {
                icon: PoliceIconSmall,
                text: 'policeCall'
            }
        case 'gas':
            return {
                icon: GasIconSmall,
                text: 'gasCall'
            }
        case 'lawyer':
            return {
                icon: LawIconSmall,
                text: 'lawCall'
            }
        case 'psychologist':
            return {
                icon: HeartIconSmall,
                text: 'psychoCall'
            }
        default:
            return {
                icon: null,
                text: null
            }
    }
}
