import React, { useState } from "react"

import { useCallContext } from "../../reducers/Call/CallProvider"

import {
	Box,
	Button,
	ButtonBase,
	Container,
	FormControl,
	IconButton,
	makeStyles,
	MenuItem,
	NativeSelect,
	Select,
	TextField,
	Typography
} from "@material-ui/core"

import ClientCardIcon from "../../assets/clienntCardGrayIcon.svg"
import birthdayIcon from "../../assets/bdGreyIcon.svg"
import VoteIcon from "../../assets/voteGrayIcon.svg"
import PhoneIcon from "../../assets/phoneGreyIcon.svg"
import CorsIcon from "../../assets/corsIcon.svg"
import BlockIcon from "../../assets/blockIcon.svg"
import Avatar from "../../assets/Photo.png"
import {
	AccountBalance,
	Business,
	Cake,
	LocationCity,
	PermContactCalendar,
	Phone,
	Room
} from "@material-ui/icons"
import { fullHeight } from "../../utils/constants"
import { SelectBlock } from "./SelectBlock"

const RenderItem = (item) => {
	const { Icon, label, id } = item

	return (
		!!label?.length && (
			<Container
				key={id}
				style={{
					flexDirection: "row",
					display: "flex",
					justifyContent: "flex-start",
					alignItems: "center",
					marginTop: 33
				}}
			>
				<Box
					style={{
						height: "100%",
						alignSelf: "flex-start",
						marginTop: 4
					}}
				>
					<Icon
						style={{
							color: "rgba(0, 0, 0, 0.54)"
						}}
					/>
				</Box>
				<Box>
					<Typography variant="h6" style={{ whiteSpace: "pre-line", marginLeft: 25 }}>
						{label}
					</Typography>
				</Box>
			</Container>
		)
	)
}

export const ClientCard = ({ closeTab }) => {
	const { callState } = useCallContext()
	const { selectedCall, selectedContact } = callState
	// const [blockedClient, setBlockedClient] = useState('Доступ надано');
	const toRender = !!selectedCall?.sessionId.length ? selectedCall : selectedContact

	const userInfo = [
		{
			id: 1,
			label: `${toRender?.user?.profile?.last_name || ""}\n${
				toRender?.user?.profile?.first_name || ""
			}\n${toRender?.user?.profile?.patronymic || ""}`,
			Icon: PermContactCalendar
		},
		{
			id: 2,
			label: toRender?.user?.profile?.birthday || "",
			Icon: Cake
		},
		{ id: 3, label: toRender?.user?.login || "", Icon: Phone },
		{
			id: 4,
			label: toRender?.user?.employee?.company?.name || "",
			Icon: Business
		},
		{
			id: 5,
			label: toRender?.user?.employee?.department?.name || "",
			Icon: AccountBalance
		},
		{
			id: 6,
			label: toRender?.user?.employee?.department?.address || "",
			Icon: Room
		}
	]
	return (
		<Box
			style={{
				padding: 0,
				margin: 0,
				maxWidth: 370,
				minWidth: 370,
				height: fullHeight,
				overflowY: "auto",
				display: "flex",
				flexDirection: "column",
				alignItems: "center"
			}}
		>
			<Box
				style={{
					width: "100%",
					height: 236,
					justifyContent: "flex-end",
					justifyItems: "flex-end"
				}}
			>
				<img
					style={{
						width: "100%",
						height: "100%"
					}}
					src={Avatar}
				/>
				<ButtonBase
					onClick={() => closeTab()}
					style={{
						position: "relative",
						bottom: "100%",
						left: "90%",
						width: 30,
						height: 30,
						borderRadius: 30
					}}
				>
					<img src={CorsIcon} />
				</ButtonBase>
			</Box>
			{userInfo.map((el) => !!el.label.trim().length && RenderItem(el))}
			<SelectBlock />
		</Box>
	)
}
