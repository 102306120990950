import {
    Box,
    IconButton,
    makeStyles,
    Paper,
    Typography
} from '@material-ui/core'
import React, { useState, useEffect } from 'react'
import OutsideClickHandler from 'react-outside-click-handler'
import dropDownArrow from '../../assets/toolBar/dropdown_arrow.svg'
import BlockIcon from '../../assets/blockIcon.svg'
import { URI } from '../../constants/defaultConstants'
import { useCallContext } from '../../reducers/Call/CallProvider'
import { RequestService } from '../../services/RequestService'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles({
    dropDownMenu: {
        position: 'absolute',
        width: 'max-content',
        height: '50px',
        background: '#fff',
        marginTop: '5px',
        zIndex: '2',
        display: 'block',
        overflowY: 'auto'
    },
    dropDownBox: {
        padding: '10px',
        '& > div': {
            cursor: 'pointer',
            padding: '5px',
            '&:hover': {
                background: '#f1f1f1',
                borderRadius: '4px'
            }
        },
        display: 'flex',
        flexDirection: 'column',
        gap: '10px'
    },
    selectBox: {
        width: '100%',
        height: '28px',
        background: '#fff',
        borderRadius: '2px',
        color: '#fff'
    }
})

export const SelectBlock = () => {
    const { t } = useTranslation()
    const classes = useStyles()
    const [isBlocked, setIsBlocked] = useState(false)
    const { callState } = useCallContext()
    const { selectedContact } = callState
    const [isMenuCalled, setIsMenuCalled] = useState(false)
    const [option, setOption] = useState(t('blockUser.choose'))

    useEffect(() => {
        if (selectedContact && selectedContact.user && selectedContact.user.blockedTill) {
            setIsBlocked(true)
        } else {
            setIsBlocked(false)
        }
        setOption(t('blockUser.choose'))
    }, [selectedContact.user])
    console.log(1)

    const blockUser = async (userId) => {
        try {
            const response = await RequestService({}).postRequest(
                `${URI.getContacts}/client/block/${userId}`
            )

            return response
        } catch (error) {
            console.log('blockUser error:', error)
        }
    }

    const handleClick = (query) => {
        setIsMenuCalled(false)
        setOption(query)
        blockUser(selectedContact.user?.id)
    }

    return (
        <Box
            display="flex"
            justifyContent="space-between"
            marginTop="20px"
            alignItems="center"
            style={{
                width: '86%',
                marginTop: 20
            }}
        >
            <Box className={classes.selectBox}>
                <Box
                    style={{
                        display: 'flex',
                        width: '100%',
                        paddingTop: '0.25em'
                    }}
                    onClick={() => setIsMenuCalled(true)}
                >
                    <IconButton>
                        <img src={BlockIcon} />
                    </IconButton>
                    <Typography style={{ marginLeft: 30 }}>{option}</Typography>
                    <img
                        src={dropDownArrow}
                        style={{ display: 'inline-block', marginLeft: 20 }}
                    />
                </Box>
                {isMenuCalled && (
                    <OutsideClickHandler
                        onOutsideClick={() => setIsMenuCalled(false)}
                    >
                        <Paper
                            onOutsideClick={() => setIsMenuCalled(false)}
                            className={classes.dropDownMenu}
                            elevation={5}
                        >
                            <Box className={classes.dropDownBox}>
                                {isBlocked ? (
                                    <div
                                        onClick={() =>
                                            handleClick(t('blockUser.unblock'))
                                        }
                                    >
                                        {t('blockUser.unblock')}
                                    </div>
                                ) : (
                                    <div
                                        onClick={() =>
                                            handleClick(t('blockUser.block'))
                                        }
                                    >
                                        {t('blockUser.block')}
                                    </div>
                                )}
                            </Box>
                        </Paper>
                    </OutsideClickHandler>
                )}
            </Box>
        </Box>
    )
}
