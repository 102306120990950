import { makeStyles, Box, IconButton, Typography, Button } from "@material-ui/core"
import React, { useEffect, useState } from "react"
import AddTranslationIcon from "../../assets/add_translation.svg"
import { useSelector } from "react-redux"
import { useActions } from "../../hooks/useActions"
import { HoveredFile } from "./HoveredFile"
import DeleteIcon from "../../assets/recorder/delete_icon.svg"
import { t } from "i18next"

const useStyles = makeStyles({
	previewfilesBox: {
		display: "flex",
		gap: "10px"
	},
	translationBox: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		gap: "20px"
	},
	recordBox: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		height: "350px",
		width: "400px"
	},
	translationFile: {
		height: "600px",
		width: "500px"
		// maxHeight: '800px'
	},
	deleteButton: {
		background: "#333",
		width: "100%",
		display: "flex",
		justifyContent: "center",
		marginTop: "-1px",
		borderRadius: "0",
		"&:hover": {
			background: "#444"
		}
	}
})

export const TranslationFiles = () => {
	const classes = useStyles()
	const { signedForTranslation, currentTranslations, completed, all } = useSelector(
		(state) => state.translationsReducer
	)
	const { selectFile, toggleCamera, setIsPressed, clearRecords, setCurrentTranslations } =
		useActions()
	const [videos, setVideos] = useState([])

	const handleRecord = (i) => {
		selectFile(i)
		toggleCamera(true)
		clearRecords(null)
	}
	const openFile = (i) => {
		selectFile(i)
		setIsPressed(true)
	}
	const adjustSource = (source) => {
		if (source === null) {
			return source
		}

		if (typeof source === "string") {
			return `https://dev.api.soc.business/v2/filesystem${source}`
		}

		if (typeof source === "object") {
			return URL.createObjectURL(source)
		}
	}

	console.log(currentTranslations, "currentTranslations")

	const isCompleted = currentTranslations.status === "completed" || completed
	const inProgress = currentTranslations.status === "processing" || signedForTranslation

	console.log("completed", currentTranslations.status === "completed")
	console.log("in progress", inProgress)

	return (
		<Box className={classes.previewfilesBox}>
			{currentTranslations.files?.map((file, i) => (
				<Box key={i} className={classes.translationBox}>
					<Box position="relative" display="flex" justifyContent="center" alignItems="center">
						<img
							src={`https://dev.api.soc.business/v2/filesystem${file.file}`}
							alt="translation file"
							className={classes.translationFile}
						/>
						<HoveredFile i={i} openFile={openFile} />
					</Box>
					<Box className={classes.recordBox}>
						{/* {console.log('show videos', currentTranslations.records[i])} */}
						{(inProgress || isCompleted) &&
							(!currentTranslations.records[i] ? (
								!isCompleted && (
									<>
										<IconButton onClick={() => handleRecord(i)}>
											<img
												src={AddTranslationIcon}
												alt="Add Translation Icon"
												style={{
													width: "35px",
													height: "35px"
												}}
											/>
										</IconButton>
										<Typography color="textSecondary">Додати переклад</Typography>
									</>
								)
							) : (
								<Box>
									<video
										height="100%"
										width="100%"
										type="video/mp4"
										src={adjustSource(currentTranslations.records[i])}
										controls
									/>
									{!isCompleted && (
										<IconButton className={classes.deleteButton} onClick={() => clearRecords(i)}>
											<img src={DeleteIcon} />
											<Typography
												style={{
													color: "#fff",
													fontWeight: "400",
													fontSize: "13px"
												}}
											>
												Видалити
											</Typography>
										</IconButton>
									)}
								</Box>
							))}
					</Box>
				</Box>
			))}
		</Box>
	)
}
