import React from "react"
import { useSelector } from "react-redux"
import { useCallContext } from "../reducers/Call/CallProvider"
import { CallPage } from "./CallPage/CallPage"
import { ContactsPage } from "./ContactsPage/ContactsPage"
import { TranslationPage } from "./TranslationPage"

export const PageRouter = () => {
	const { callState }: any = useCallContext()
	const { selectedCall } = callState

	const { page } = useSelector((state: any) => state.paginationReducer)

	const pages = [<CallPage />, <ContactsPage />, <TranslationPage />]

	return selectedCall.sessionId.length ? pages[0] : pages.find((el, i: number) => i === page && el)
}
