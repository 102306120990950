import React from 'react'
import * as ReactDOMClient from 'react-dom/client'
import { App } from './App'
import './translations/helpers/translations'
import './index.css'
// require("dotenv").config()

// switch (process?.env?.REACT_ENV_TYPE) {
// 	case "dev":
// 		title.innerText = "Connect Operator (Development)"
// 		break
// 	case "stage":
// 		title.innerText = "Connect Operator (Stage)"
// 		break
// 	default:
// 		title.innerText = "Connect Operator"
// }

const root = ReactDOMClient.createRoot(
    document.getElementById('root') as HTMLElement
)
root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
)

// render(<App />, document.getElementById('root'));
