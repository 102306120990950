import React, { useState } from 'react'
import { Box, ButtonBase, Divider, Typography } from '@material-ui/core'
import VideoCallIcon from '../../assets/videoCallIcon.svg'
import VideoCallIconHist from '../../assets/videoCallIconHist.svg'

import Lottie from 'react-lottie'

import { CallActionTypes } from '../../reducers/Call/CallActionsTypes'
import { useCallContext } from '../../reducers/Call/CallProvider'
import { CallService } from '../../services/CallService'
import { useEffect } from 'react'
import moment from 'moment'
import { CallHistoryItem } from './CallHistoryItem'

export const CallHistory = ({ isHistoryRender }) => {
    const { callState, dispatchCall } = useCallContext()
    const [page, setPage] = useState(1)
    const handleNextPage = () => setPage(page + 1)

    async function getHistory() {
        const res = await CallService.getCallHistory(page)
        console.log('HISTORY RES', res)

        if (res) {
            const result = {
                data: res,
                next_page: page + 1
            }

            dispatchCall({
                type: CallActionTypes.GET_CALL_HISTORY,
                payload: result
            })
        }
    }

    useEffect(() => {
        getHistory()
    }, [page])

    useEffect(() => {
        return () => {
            dispatchCall({ type: CallActionTypes.CLEAR_CALL_HISTORY })
            dispatchCall({
                type: CallActionTypes.SET_SELECTED_HISTORY,
                payload: {}
            })
        }
    }, [])

    return (
        <div
            style={{
                height: 410,
                overflow: 'scroll',
                marginBottom: 80
            }}
        >
            <CallHistoryItem />
            {callState.nextPage && (
                <ButtonBase
                    onClick={() => handleNextPage()}
                    style={{
                        width: '100%',
                        bottom: 0,
                        height: 50,
                        backgroundColor: '#00000030'
                    }}
                >
                    Завантажити ще
                </ButtonBase>
            )}
        </div>
    )
}
