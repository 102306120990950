import { makeStyles, Box } from "@material-ui/core"
import React, { useState } from "react"
import magnifier from "../../assets/toolBar/zoom-in.svg"

export const HoveredFile = ({ i, openFile }) => {
	const [styleOpacity, setStyleOpacity] = useState(0)
	const [styleVisibility, setStyleVisibility] = useState("hidden")
	const useStyles = makeStyles({
		onHover: {
			height: "100%",
			width: "100%",
			background: "#c1c1c1",
			position: "absolute",
			top: "0",
			opacity: styleOpacity
		},
		loupe: {
			width: "56px",
			height: "56px"
		},
		loupeBox: {
			position: "absolute",
			visibility: styleVisibility
		}
	})

	const classes = useStyles()

	const handleHover = (mode) => {
		if (mode) {
			setStyleOpacity(0.5)
			setStyleVisibility("visible")
		} else {
			setStyleOpacity(0)
			setStyleVisibility("hidden")
		}
	}

	return (
		<>
			<Box
				className={classes.onHover}
				onClick={() => openFile(i)}
				onMouseOver={() => handleHover(true)}
				onMouseLeave={() => handleHover(false)}
			/>
			<Box
				className={classes.loupeBox}
				onClick={() => openFile(i)}
				onMouseOver={() => handleHover(true)}
			>
				<img src={magnifier} className={classes.loupe} />
			</Box>
		</>
	)
}
