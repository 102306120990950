export const ENV_TYPE = 'stage'

export const COMMENT_TYPES = {
    TRANSLATION_REQUEST: 'TRANSLATION_REQUEST',
    CLIENT_CALL: 'CLIENT_CALL',
    CLIENT_ENTITY: 'CLIENT_ENTITY'
}

export const INTERPRETER_STATE = {
    SIGN_IN: 'SIGN_IN',
    SIGN_OUT: 'SIGN_OUT',
    AUTH_ERROR: 'AUTH_ERROR'
}

export const URI = {
    translations: 'v2/translation_requests',
    login: '/v2/auth/login/email',
    logout: '/v2/auth/logout',
    getContacts: '/v2/user',
    findContacts: '/v2/user/login',
    getContactData: 'v2/user/login/edit',
    callHist: '/v2/call/history',
    getIncomingCalls: '/v2/call/initiated',
    status: '/api/v3/interpreter/status',
    blockClient: '/api/interpreter/block_client',
    getClientCalls: '/api/interpreter/get_calls',
    getGroupCalls: '/api/v3/interpreter/group',
    getTranslationRequests: '/api/v3/interpreter/requests',
    answerCall: '/api/interpreter/answer_call',
    getInterpreters: '/api/v3/interpreter',
    note: 'api/v3/interpreter/note',
    clients: 'api/v3/interpreter/clients'
}

export const itemList = [
    {
        label: 'Заблокировать',
        id: 1
    },
    {
        label: 'Сменить тему вызова',
        id: 2
    }
]

export const dayCorrectName = [
    'день', // 1, 21, 31, 41...
    'дня', // 2-4, 22-24, 32-34, 42-44...
    'дней' // 5-20, 25-30, 35-40...
]

export const hourCorrectName = [
    'час', // 1, 21, 31, 41...
    'часа', // 2-4, 22-24, 32-34, 42-44...
    'часов' // 5-20, 25-30, 35-40...
]

export const minCorrectName = [
    'минута', // 1, 21, 31, 41...
    'минуты', // 2-4, 22-24, 32-34, 42-44...
    'минут' // 5-20, 25-30, 35-40...
]
