import React, { useEffect, useState } from 'react'
import { Box, ButtonBase, Typography } from '@material-ui/core'
import { Transition } from 'react-transition-group'

import DeclindeBtn from '../../assets/declineButton.svg'
import CamOnIcon from '../../assets/camOnIcon.svg'
import CamOffIcon from '../../assets/camOffIcon.svg'
import MicOnIcon from '../../assets/micOnIcon.svg'
import MicOffIcon from '../../assets/micOffIcon.svg'
import AudioOnIcon from '../../assets/sound-off-icon.svg'
import AudioOffIcon from '../../assets/volumeIconOff.svg'
import { useCallContext } from '../../reducers/Call/CallProvider'
import { CallActionTypes } from '../../reducers/Call/CallActionsTypes'
import { Timer } from '../../utils/Timer'
import moment from 'moment'
import { fullHeight } from '../../utils/constants'
import { useTranslation } from 'react-i18next'

export const CallButtons = ({
    setCanStartCall,
    profile,
    setAudioIsSound,
    audioIsSound,
    callReason
}) => {
    const { t } = useTranslation()
    const {
        publisher,
        dispatchCall,
        sessionRef,
        callState,
        publisherVideo,
        setPublisherVideo,
        publisherAudio,
        setPublisherAudio
    } = useCallContext()

    const isGroupCall = callReason === 'group'

    return (
        <Box
            style={{
                ...styles.container
            }}
        >
            <Box>
                <Typography variant="h4">
                    {profile?.first_name} {profile?.last_name}
                </Typography>
                {!callState.selectedCall.user.employee &&
                    callState.selectedCall?.user?.balance && (
                        <Typography variant="h4" style={{ fontSize: 20 }}>
                            {t('call.minutesAtBegin')}
                            {(
                                callState.selectedCall.user?.balance?.balance /
                                60
                            ).toFixed(0)}
                        </Typography>
                    )}
                <Typography variant="h4">
                    <Timer
                        start={
                            sessionRef?.current?.state.streams[0].creationTime
                        }
                    />
                </Typography>
            </Box>
        </Box>
    )
}

const styles = {
    container: {
        position: 'relative',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        justifyItems: 'space-between',
        height: fullHeight,
        paddingTop: 40,
        paddingBottom: 40,
        top: 'calc(-100vh + 40px)'
    },
    buttons: {
        display: 'flex',
        justifyContent: 'center',
        alignSelf: 'center'
    },
    buttonWrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: '0 10px'
    },
    buttonIcon: {
        width: 50,
        height: 50,
        borderRadius: '50%',
        marginBottom: 10
    }
}
